"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OAuthClientToJSON = exports.OAuthClientFromJSONTyped = exports.OAuthClientFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function OAuthClientFromJSON(json) {
    return OAuthClientFromJSONTyped(json, false);
}
exports.OAuthClientFromJSON = OAuthClientFromJSON;
function OAuthClientFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'name': json['name'],
        'logo': !(0, runtime_1.exists)(json, 'logo') ? undefined : json['logo'],
        'owner': json['owner'],
        'isEnabled': json['isEnabled'],
        'redirectUris': json['redirectUris'],
        'grants': !(0, runtime_1.exists)(json, 'grants') ? undefined : (json['grants'].map(_1.OAuthGrantsFromJSON)),
        'scopes': !(0, runtime_1.exists)(json, 'scopes') ? undefined : (json['scopes'].map(_1.OAuthScopesFromJSON)),
    };
}
exports.OAuthClientFromJSONTyped = OAuthClientFromJSONTyped;
function OAuthClientToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'logo': value.logo,
        'owner': value.owner,
        'isEnabled': value.isEnabled,
        'redirectUris': value.redirectUris,
        'grants': value.grants === undefined ? undefined : (value.grants.map(_1.OAuthGrantsToJSON)),
        'scopes': value.scopes === undefined ? undefined : (value.scopes.map(_1.OAuthScopesToJSON)),
    };
}
exports.OAuthClientToJSON = OAuthClientToJSON;
