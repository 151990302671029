"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OAuthTokenToJSON = exports.OAuthTokenFromJSONTyped = exports.OAuthTokenFromJSON = void 0;
var _1 = require("./");
function OAuthTokenFromJSON(json) {
    return OAuthTokenFromJSONTyped(json, false);
}
exports.OAuthTokenFromJSON = OAuthTokenFromJSON;
function OAuthTokenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accessToken': json['accessToken'],
        'accessTokenExpiresAt': json['accessTokenExpiresAt'],
        'refreshToken': json['refreshToken'],
        'refreshTokenExpiresAt': json['refreshTokenExpiresAt'],
        'client': (0, _1.OAuthClientFromJSON)(json['client']),
        'user': (0, _1.OAuthTokenUserFromJSON)(json['user']),
        'scope': (json['scope'].map(_1.OAuthScopesFromJSON)),
    };
}
exports.OAuthTokenFromJSONTyped = OAuthTokenFromJSONTyped;
function OAuthTokenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accessToken': value.accessToken,
        'accessTokenExpiresAt': value.accessTokenExpiresAt,
        'refreshToken': value.refreshToken,
        'refreshTokenExpiresAt': value.refreshTokenExpiresAt,
        'client': (0, _1.OAuthClientToJSON)(value.client),
        'user': (0, _1.OAuthTokenUserToJSON)(value.user),
        'scope': (value.scope.map(_1.OAuthScopesToJSON)),
    };
}
exports.OAuthTokenToJSON = OAuthTokenToJSON;
