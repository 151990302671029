"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OAuthScopesToJSON = exports.OAuthScopesFromJSONTyped = exports.OAuthScopesFromJSON = exports.OAuthScopes = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var OAuthScopes;
(function (OAuthScopes) {
    OAuthScopes["VIEW"] = "MERCHANTS_VIEW";
    OAuthScopes["CONNECT"] = "MERCHANTS_CONNECT";
})(OAuthScopes = exports.OAuthScopes || (exports.OAuthScopes = {}));
function OAuthScopesFromJSON(json) {
    return OAuthScopesFromJSONTyped(json, false);
}
exports.OAuthScopesFromJSON = OAuthScopesFromJSON;
function OAuthScopesFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OAuthScopesFromJSONTyped = OAuthScopesFromJSONTyped;
function OAuthScopesToJSON(value) {
    return value;
}
exports.OAuthScopesToJSON = OAuthScopesToJSON;
