"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OAuthStreamRefreshTokenResponseToJSON = exports.OAuthStreamRefreshTokenResponseFromJSONTyped = exports.OAuthStreamRefreshTokenResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
function OAuthStreamRefreshTokenResponseFromJSON(json) {
    return OAuthStreamRefreshTokenResponseFromJSONTyped(json, false);
}
exports.OAuthStreamRefreshTokenResponseFromJSON = OAuthStreamRefreshTokenResponseFromJSON;
function OAuthStreamRefreshTokenResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'access_token': json['access_token'],
        'refresh_token': json['refresh_token'],
        'expire_time': json['expire_time'],
        'refresh_token_expire_time': !(0, runtime_1.exists)(json, 'refresh_token_expire_time') ? undefined : json['refresh_token_expire_time'],
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
    };
}
exports.OAuthStreamRefreshTokenResponseFromJSONTyped = OAuthStreamRefreshTokenResponseFromJSONTyped;
function OAuthStreamRefreshTokenResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'access_token': value.access_token,
        'refresh_token': value.refresh_token,
        'expire_time': value.expire_time,
        'refresh_token_expire_time': value.refresh_token_expire_time,
        'id': value.id,
    };
}
exports.OAuthStreamRefreshTokenResponseToJSON = OAuthStreamRefreshTokenResponseToJSON;
