"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWRewardsToJSON = exports.OWRewardsFromJSONTyped = exports.OWRewardsFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function OWRewardsFromJSON(json) {
    return OWRewardsFromJSONTyped(json, false);
}
exports.OWRewardsFromJSON = OWRewardsFromJSON;
function OWRewardsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'game_id': json['game_id'],
        'reward_type': (0, _1.OWRewardTypeFromJSON)(json['reward_type']),
        'reward_amount': json['reward_amount'],
        'reward_max': json['reward_max'],
        'reward_metaData': !(0, runtime_1.exists)(json, 'reward_metaData') ? undefined : json['reward_metaData'],
        'reward_category': (0, _1.OWLogCategoryFromJSON)(json['reward_category']),
        'event': json['event'],
        'progress': json['progress'],
        'target': json['target'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'id': json['id'],
    };
}
exports.OWRewardsFromJSONTyped = OWRewardsFromJSONTyped;
function OWRewardsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'game_id': value.game_id,
        'reward_type': (0, _1.OWRewardTypeToJSON)(value.reward_type),
        'reward_amount': value.reward_amount,
        'reward_max': value.reward_max,
        'reward_metaData': value.reward_metaData,
        'reward_category': (0, _1.OWLogCategoryToJSON)(value.reward_category),
        'event': value.event,
        'progress': value.progress,
        'target': value.target,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'id': value.id,
    };
}
exports.OWRewardsToJSON = OWRewardsToJSON;
