"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OAuthCodeToJSON = exports.OAuthCodeFromJSONTyped = exports.OAuthCodeFromJSON = void 0;
var _1 = require("./");
function OAuthCodeFromJSON(json) {
    return OAuthCodeFromJSONTyped(json, false);
}
exports.OAuthCodeFromJSON = OAuthCodeFromJSON;
function OAuthCodeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'authorizationCode': json['authorizationCode'],
        'expiresAt': json['expiresAt'],
        'redirectUri': json['redirectUri'],
        'client': (0, _1.OAuthClientFromJSON)(json['client']),
        'user': (0, _1.OAuthCodeUserFromJSON)(json['user']),
        'scope': (json['scope'].map(_1.OAuthScopesFromJSON)),
    };
}
exports.OAuthCodeFromJSONTyped = OAuthCodeFromJSONTyped;
function OAuthCodeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'authorizationCode': value.authorizationCode,
        'expiresAt': value.expiresAt,
        'redirectUri': value.redirectUri,
        'client': (0, _1.OAuthClientToJSON)(value.client),
        'user': (0, _1.OAuthCodeUserToJSON)(value.user),
        'scope': (value.scope.map(_1.OAuthScopesToJSON)),
    };
}
exports.OAuthCodeToJSON = OAuthCodeToJSON;
