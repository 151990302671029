"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StreamLocationAddressToJSON = exports.StreamLocationAddressFromJSONTyped = exports.StreamLocationAddressFromJSON = void 0;
var runtime_1 = require("../runtime");
function StreamLocationAddressFromJSON(json) {
    return StreamLocationAddressFromJSONTyped(json, false);
}
exports.StreamLocationAddressFromJSON = StreamLocationAddressFromJSON;
function StreamLocationAddressFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'address_line_1': json['address_line_1'],
        'address_line_2': !(0, runtime_1.exists)(json, 'address_line_2') ? undefined : json['address_line_2'],
        'city': json['city'],
        'state': json['state'],
        'postal_code': json['postal_code'],
        'country': json['country'],
    };
}
exports.StreamLocationAddressFromJSONTyped = StreamLocationAddressFromJSONTyped;
function StreamLocationAddressToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'address_line_1': value.address_line_1,
        'address_line_2': value.address_line_2,
        'city': value.city,
        'state': value.state,
        'postal_code': value.postal_code,
        'country': value.country,
    };
}
exports.StreamLocationAddressToJSON = StreamLocationAddressToJSON;
