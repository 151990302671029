import React, {ReactElement, useEffect, useState} from "react";
import {
		OAuthClient,
		OAuth2Api,
		AssetsApi,
		Asset,
} from "@devour/client";
import getConfig from "../utils/getConfig";
import {createSearchParams, useSearchParams} from "react-router-dom";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/metaActions";
import {useDispatch} from "react-redux";
import FrameButton from "../components/buttons/FrameButton";

function OAuthorize(): ReactElement {
		const dispatch = useDispatch();
		const [searchParams] = useSearchParams();
		const oAuthClientId = searchParams.get("client_id");
		const oAuthRedirectUri = searchParams.get("redirect_uri");
		const oAuthResponseType = searchParams.get("response_type");
		const oAuthState = searchParams.get("state");
		const oAuthScope = searchParams.get("scope");
		const [oAuthClient, setOAuthClient] = useState<OAuthClient>();
		const [logo, setLogo] = useState<Asset>();
		const enabledScopes = oAuthScope?.split(" ") || oAuthClient?.scopes || [];

		useEffect(() => {
				void fetchOAuthClient();
		}, [oAuthClientId]);

		useEffect(() => {
				void fetchLogo();
		}, [oAuthClient]);

		async function fetchOAuthClient(): Promise<void> {
				if (!oAuthClientId) {
						return;
				}
				dispatch(incrementLoading());
				try {
						const res = await new OAuth2Api(getConfig()).oAuthGetClient({
								id: oAuthClientId,
						});
						setOAuthClient(res);
				} catch (e) {
						dispatch(await addError(e));
				} finally {
						dispatch(decrementLoading());
				}
		}

		async function fetchLogo(): Promise<void> {
				if (!oAuthClient?.logo) {
						return;
				}
				try {
						const res = await new AssetsApi(getConfig()).getAsset({
								id: oAuthClient.logo,
						});
						setLogo(res);
				} catch (e) {
						dispatch(await addError(e));
				}
		}

		async function authorizeOAuthClient(): Promise<void> {
				dispatch(incrementLoading());
				try {
						const res = await new OAuth2Api(getConfig()).oAuthCode({
								clientId: oAuthClientId,
								redirectUri: oAuthRedirectUri,
								responseType: oAuthResponseType,
								state: oAuthState,
								scope: enabledScopes.join(" "),
						});
						const params: Record<string, string> = {
								code: res.authorizationCode,
						};
						for (let [key, value] of searchParams.entries()) {
								params[key] = value;
						}
						window.location.href = `${res.redirectUri}?${createSearchParams(params).toString()}`;
				} catch (e) {
						dispatch(await addError(e));
				} finally {
						dispatch(decrementLoading());
				}
		}

		function rejectOAuthClient(): void {
				const params: Record<string, string> = {
						error: "access_denied",
				};
				for (let [key, value] of searchParams.entries()) {
						params[key] = value;
				}
				const redirectUri = (oAuthRedirectUri && oAuthClient?.redirectUris.includes(oAuthRedirectUri))
						? oAuthRedirectUri
						: oAuthClient.redirectUris[0];
				window.location.href = `${redirectUri}?${createSearchParams(params).toString()}`;
		}

		if (!oAuthClient) {
				return null;
		}

		return (
				<div className="oauth-grant-page">
						<div className="oauth-grant-page_content">
								{logo && (
										<div className="oauth-grant-page_logo">
												<img src={logo.url} alt={oAuthClient.name}/>
										</div>
								)}
								<div className="oauth-grant-page_body">
										<h2>
												An application would like to connect to your account
										</h2>

										<p>
												The app {oAuthClient.name} would like the ability to access the following on
												your DevourGO account:
										</p>

										<ul>
												{enabledScopes.map((scope) => (
														<li key={scope}>
																{scope}
														</li>
												))}
										</ul>

										<p>
												Allow {oAuthClient.name} access?
										</p>

										<div className="oauth-grant-page_actions">
												<FrameButton
														<React.ButtonHTMLAttributes<HTMLButtonElement>>
														color="danger"
														size="normal"
														onClick={rejectOAuthClient}
												>
														Deny
												</FrameButton>

												<FrameButton
														<React.ButtonHTMLAttributes<HTMLButtonElement>>
														color="success"
														size="normal"
														onClick={authorizeOAuthClient}
												>
														Authorize
												</FrameButton>

										</div>
								</div>
						</div>
				</div>
		);
}

export default OAuthorize;
