"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OAuthRefreshTokenBodyToJSON = exports.OAuthRefreshTokenBodyFromJSONTyped = exports.OAuthRefreshTokenBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function OAuthRefreshTokenBodyFromJSON(json) {
    return OAuthRefreshTokenBodyFromJSONTyped(json, false);
}
exports.OAuthRefreshTokenBodyFromJSON = OAuthRefreshTokenBodyFromJSON;
function OAuthRefreshTokenBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'grant_type': json['grant_type'],
        'client_id': json['client_id'],
        'client_secret': json['client_secret'],
        'code': !(0, runtime_1.exists)(json, 'code') ? undefined : json['code'],
        'refresh_token': !(0, runtime_1.exists)(json, 'refresh_token') ? undefined : json['refresh_token'],
        'redirect_uri': json['redirect_uri'],
    };
}
exports.OAuthRefreshTokenBodyFromJSONTyped = OAuthRefreshTokenBodyFromJSONTyped;
function OAuthRefreshTokenBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'grant_type': value.grant_type,
        'client_id': value.client_id,
        'client_secret': value.client_secret,
        'code': value.code,
        'refresh_token': value.refresh_token,
        'redirect_uri': value.redirect_uri,
    };
}
exports.OAuthRefreshTokenBodyToJSON = OAuthRefreshTokenBodyToJSON;
