"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StreamLocationConnectionResponseToJSON = exports.StreamLocationConnectionResponseFromJSONTyped = exports.StreamLocationConnectionResponseFromJSON = void 0;
function StreamLocationConnectionResponseFromJSON(json) {
    return StreamLocationConnectionResponseFromJSONTyped(json, false);
}
exports.StreamLocationConnectionResponseFromJSON = StreamLocationConnectionResponseFromJSON;
function StreamLocationConnectionResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location_id': json['location_id'],
        'connected': json['connected'],
    };
}
exports.StreamLocationConnectionResponseFromJSONTyped = StreamLocationConnectionResponseFromJSONTyped;
function StreamLocationConnectionResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location_id': value.location_id,
        'connected': value.connected,
    };
}
exports.StreamLocationConnectionResponseToJSON = StreamLocationConnectionResponseToJSON;
