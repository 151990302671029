"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OAuthCodeUserToJSON = exports.OAuthCodeUserFromJSONTyped = exports.OAuthCodeUserFromJSON = void 0;
function OAuthCodeUserFromJSON(json) {
    return OAuthCodeUserFromJSONTyped(json, false);
}
exports.OAuthCodeUserFromJSON = OAuthCodeUserFromJSON;
function OAuthCodeUserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
    };
}
exports.OAuthCodeUserFromJSONTyped = OAuthCodeUserFromJSONTyped;
function OAuthCodeUserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
    };
}
exports.OAuthCodeUserToJSON = OAuthCodeUserToJSON;
