"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OAuthGrantsToJSON = exports.OAuthGrantsFromJSONTyped = exports.OAuthGrantsFromJSON = exports.OAuthGrants = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var OAuthGrants;
(function (OAuthGrants) {
    OAuthGrants["AuthorizationCode"] = "authorization_code";
    OAuthGrants["RefreshToken"] = "refresh_token";
})(OAuthGrants = exports.OAuthGrants || (exports.OAuthGrants = {}));
function OAuthGrantsFromJSON(json) {
    return OAuthGrantsFromJSONTyped(json, false);
}
exports.OAuthGrantsFromJSON = OAuthGrantsFromJSON;
function OAuthGrantsFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OAuthGrantsFromJSONTyped = OAuthGrantsFromJSONTyped;
function OAuthGrantsToJSON(value) {
    return value;
}
exports.OAuthGrantsToJSON = OAuthGrantsToJSON;
